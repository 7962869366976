<template lang="pug">
ui-dialog(
  closable
  width="800px"
  header="Список отраслей"
  :z-index="3"
  :visible-prop="show"
  @content:hide="hide"
)
  template(#content)
    inner-industry-tree(v-model="innerIndustries" mode="dialog" @reset="resetInnerIndustries")
  template(#actions)
    ui-button(@click="confirm") Сохранить
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { useVModel } from "@vueuse/core";
import type { PropType } from "vue";

import UiDialog from "@/components/ui/dialog/UiDialog.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import InnerIndustryTree from "@/components/searchForm/elements/InnerIndustryTree.vue";

export default defineComponent({
  name: "IndustryDialog",
  components: {
    UiDialog,
    UiButton,
    InnerIndustryTree,
  },
  emits: [
    "update:show",
    "update:industryIds",
  ],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    industryIds: {
      type: Array as PropType<number[]>,
      default: () => ([]),
    },
  },
  setup(props, { emit }) {

    const industryIdsModel = useVModel(props, 'industryIds', emit);

    const innerIndustries = ref([...industryIdsModel.value]);

    watch(industryIdsModel, () => {
      innerIndustries.value = [...industryIdsModel.value]
    })

    function hide() {
      emit('update:show', false);
      innerIndustries.value = [...industryIdsModel.value]
    }

    function confirm() {
      industryIdsModel.value = innerIndustries.value
      hide()
    }

    function resetInnerIndustries() {
      innerIndustries.value = []
    }

    return {
      innerIndustries,
      hide,
      confirm,
      resetInnerIndustries,
    }
  }
})
</script>

<style scoped lang="scss">

</style>
