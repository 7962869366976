<template lang="pug">
regions-tree.delivery-tree(
  v-model="modelValue"
  title="МЕСТО ВЫПОЛНЕНИЯ РАБОТ"
  store-variable-name="deliveryPlaceIds"
  @reset="$emit('reset', $event)"
)
.delivery-fulltext
  cleanable-title(@reset="$emit('reset', 'lotsSearch.deliveryPlace')")
    span МЕСТО ВЫПОЛНЕНИЯ РАБОТ (полнотекстовый поиск)
    template(#additional)
      keywords-warning(type="delivery")
  tag-input(
    writable
    placeholder="Введите места поставки"
    v-model="modelValue.lotsSearch.deliveryPlace"
  )
</template>

<script lang="ts">
import { defineComponent } from "vue";

import RegionsTree from "@/components/searchForm/filters/RegionsTree.vue";
import TagInput from "@/components/tagInput/TagInput.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";
import KeywordsWarning from "~/components/searchForm/elements/KeywordsWarning.vue";

import type { PropType } from "vue";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";

export default defineComponent({
  name: "DeliveryPlace",
  components: {
    TagInput,
    RegionsTree,
    CleanableTitle,
    KeywordsWarning,
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
  },
});
</script>

<style scoped lang="scss">
.delivery-tree {
  width: 100%;
  max-width: var(--max-advanced-filters-width);
}

.delivery-fulltext {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: var(--max-advanced-filters-width);

  :deep(#tag-editor) {
    height: 100%;
    min-height: 140px;
  }
}
</style>
