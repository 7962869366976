<template lang="pug">
.price_and_payment_terms
  cleanable-title(@reset="cleanCost")
    span Начальная цена
    template(#additional)
      ui-warning(placement="bottom-end")
        .warning-content
          span.label Фильтр по цене и размеру обеспечения
          span {{ hint }}
          span При установке параметра "искать с неопределенной ценой", в выдаче будут присутствовать закупки с нулевой или неизвестной ценой.
  price-and-payment-terms(
    id="onFilterPanel"
    v-model:cost="modelValue.cost"
    v-model:costApp="modelValue.costApp"
  )
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";
import PriceAndPaymentTerms from "@/components/searchForm/elements/PriceAndPaymentTerms.vue";
import UiWarning from "@/components/ui/tooltip/UiWarning.vue";
import type { PropType } from "vue";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";

export default defineComponent({
  name: "CostFilter",
  components: {
    UiWarning,
    CleanableTitle,
    PriceAndPaymentTerms,
  },
  props: {
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
  },
  emits: [
    'reset',
  ],
  setup(props, { emit }) {

    const hint = "Фильтр по суммам будет учитывать указанную валюту." +
      " Если указанная валюта и валюта закупки не совпадают, то фильтрация будет происходить по курсу" +
      " Центрального банка РФ, действительную на текущий день."

    function cleanCost() {
      emit('reset', 'cost')
      emit('reset', 'costApp')
    }

    return {
      hint,
      cleanCost,
    }
  }
})
</script>

<style scoped lang="scss">
.price_and_payment_terms {
  display: flex;
  flex-flow: column;
  gap: 4px;
  width: 100%;
}

.warning-content {
  display: flex;
  flex-flow: column;
  gap: 6px;
  max-width: 420px;
  padding: 4px;

  .label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
}

.cost-title {
  display: flex;
  gap: 4px;
}
</style>
