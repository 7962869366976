<template lang="pug">
.lot-status
  cleanable-title(@reset="$emit('reset', 'lotStatusIds')") ЭТАП ЗАКУПКИ
  checkbox-group.filter-card__checkboxes(v-model="modelValue.lotStatusIds" :options="filters")
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PropType } from "vue";
import type { SearchFormI } from "@/stores/search/SearchFormInterface";
import CheckboxGroup from "@/components/ui/checkbox/CheckboxGroup.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";
import useManualsStore, { lotStatusOptions } from "~/stores/manuals/useManualsStore";

export default defineComponent({
  name: "LotStatus",
  components: {
    CheckboxGroup,
    CleanableTitle,
  },
  props: {
    modelValue: {
      type: Object as PropType<SearchFormI>,
      default: () => ({}),
    },
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  setup() {

    const manualsStore = useManualsStore()
    const filters = lotStatusOptions || []

    return {
      filters,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/searchForm";

.lot-status {
  display: flex;
  flex-flow: column;

  .filter-card__checkboxes {
    @include checkboxes_column;
  }
}
</style>
