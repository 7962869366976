<template lang="pug">
.search-form(v-show="showSearchForm")
  ui-tabs.tabs(v-model="activeTabModel" :tabs="computedTabs")
    icon-button.close-button(v-if="showHideButton" as-button button-type="secondary" :icon="UiIconNames.Icon_EyeClose" tooltip-title="Скрыть поисковую форму" @click="hideAdvanced")
  template(v-for="tab of tabs" :key="tab.name")
    .filter-wrapper(v-show="activeTabModel === tab.key")
      advanced-search(:cards="tab.cards" :grid-view="tab.isGridView" v-model="searchForm" @reset="resetField")
  search-form-buttons(
    :tab="tab"
    :edit-mode="editMode"
    :warning-type="warningType"
    :show-warning="hasFiltersChanges"
    @start:search="$emit('start:search')"
    @start:searchOnly="$emit('start:searchOnly')"
    @start:analyse="startAnalyse"
    @form:reset="resetForm"
    @form:exit="$emit('form:exit')"
    @save:changes="$emit('save:changes')"
    @save:template="$emit('save:template')"
  )

pop-confirm(v-model:show="showPopConfirm" header="Покинуть режим расширенного поиска?" exit-button-label="Скрыть поисковую форму" @event="popConfirmEvent")
  .confirm
    span Внесенные изменения не будут сохранены. При выходе из режима расширенного поиска, все данные будут сброшены, после чего Вам будет доступен как быстрый, так и расширенный поиск.
    span.secondary(v-if="enableTemplates && !enableOnlyExpertTemplates") Вы можете сохранить настройки фильтров в шаблон, для этого нажмите кнопку "Сохранить шаблон"
    span.secondary Для того, чтобы очистить введенные данные, нажмите кнопку "Сбросить все фильтры"
</template>

<script lang="ts">
import { computed, defineAsyncComponent, defineComponent } from 'vue';
import UiTabs from "@/components/ui/tab/UiTabs.vue";
import SearchFormButtons from "@/components/searchForm/SearchFormButtons.vue";
import AdvancedSearch from "@/components/searchForm/AdvancedSearch.vue";
import IconButton from "@/components/ui/button/IconButton.vue";

import type { PropType } from 'vue';
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";
import type { TabMode } from "@/stores/search/SearchFormInterface";

import { useVModel } from "@vueuse/core";
import { getDefaultSearchForm } from "@/utils/getters/defaultSearchForm";
import { useSearchFormCommon } from "@/use/searchForm/useSearchForm";
import { useAdvancedFilters } from "@/components/searchForm/useAdvancedFilters";
import { usePopConfirm } from "@/use/confirm/usePopConfirm";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "SearchForm",
  components: {
    IconButton,
    UiTabs,
    AdvancedSearch,
    SearchFormButtons,
    PopConfirm: defineAsyncComponent(() => import("@/components/other/PopConfirm.vue")),
  },
  emits: [
    'update:searchForm',
    'update:showSearchForm',
    'update:activeTab',
    'start:searchOnly',    // начать поиск без сохранения фильтров (при  editMode)
    'start:search',        // начать поиск
    'start:analyse',       // начать анализ
    'save:changes',        // обновить шаблон
    'save:template',       // сохранить шаблон
    'show:error',
    'form:exit',           // выйти без сохранения
    'hide:searchForm',
  ],
  props: {
    tab: {
      type: String as PropType<TabMode>,
      default: 'LotsListSearch',
    },
    warningType: {
      type: String,
      default: '',
    },
    activeTab: {
      type: String,
      default: 'default',
    },
    searchForm: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
    showSearchForm: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    hasFiltersChanges: {
      type: Boolean,
      default: false,
    },
    hasDefaultChanges: {
      type: Boolean,
      default: false,
    },
    showHideButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {

    const activeTabModel = useVModel(props, 'activeTab', context.emit)
    const searchFormModel = useVModel(props, 'searchForm', context.emit)

    const defaultSearchForm = getDefaultSearchForm(props.tab)

    const {
      tabs,
      prepareSF,
    } = useAdvancedFilters(props.tab)

    const computedTabs = computed(() =>
      tabs.map(e => ({ name: e.name, key: e.key, badge: countFilters(e.names) }))
    )

    function countFilters(names: string[][]) {
      return prepareSF(names, searchFormModel, defaultSearchForm)
    }

    const { resetCurrentField, resetSearchForm } = useSearchFormCommon()
    const { enableTemplates, enableOnlyExpertTemplates } = useUserAccess()

    const {
      showPopConfirm,
      openPopConfirm,
      popConfirmEvent,
    } = usePopConfirm();

    /** скрыть панель фильтров */
    function hideAdvanced() {
      context.emit('hide:searchForm')
    }

    /** очистить фильтр */
    function resetField(field: string) {
      resetCurrentField(searchFormModel, props.tab, field)
    }

    /** сбросить все фильтры */
    function resetForm() {
      resetSearchForm(searchFormModel, props.tab)
    }

    /** начать новый анализ */
    function startAnalyse() {
      if (props.tab === 'AnalyseIndustry' && !(searchFormModel.value.datePublic?.dateFrom && searchFormModel.value.datePublic?.dateTo)) {
        activeTabModel.value = 'default'
        // todo alert
        context.emit('show:error', 'Предварительное условие не задано. Пожалуйста, задайте фильтр по дате публикации')
      } else if ((props.tab === 'AnalyseCustomers' || props.tab === 'AnalyseSuppliers') && !searchFormModel.value?.kpp && !searchFormModel.value?.needAllFilials) {
        activeTabModel.value = 'default'
        context.emit('start:analyse')
      } else {
        context.emit('start:analyse')
      }
    }

    return {
      tabs,
      activeTabModel,
      computedTabs,
      showPopConfirm,
      enableTemplates,
      enableOnlyExpertTemplates,
      hideAdvanced,
      openPopConfirm,
      popConfirmEvent,
      resetField,
      resetForm,
      startAnalyse,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
.search-form {
  display: flex;
  flex-flow: column;

  border: 1px solid #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 8px 10px #cdcdcd24, 0 3px 15px #b9bfc51f, 0 4px 5px #cfcfcf33;
  background-color: white;

  .filter-wrapper {
    width: 100%;
    padding: 24px 46px;
    box-sizing: border-box;
  }

  .tabs {
    margin-left: 43px;
  }

  :deep(.close-button) {
    margin-right: 43px;
    margin-left: auto;
    width: fit-content;
    height: fit-content;
  }
}

.confirm {
  display: flex;
  flex-flow: column;
  gap: 8px;

  .secondary {
    color: var(--secondary-text-color);
  }
}
</style>
