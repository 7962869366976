<template lang="pug">
.slider(:style="`grid-template-columns: repeat(${ options.length }, minmax(52px, 1fr));`")
  span(
    v-for="item of options"
    v-html="item.label"
    :key="item.id"
    :class="[modelValue === item.value && 'active', size]"
    @click="$emit('update:modelValue', item.value)"
  )
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from "vue";

interface RadioButtonI {
  id: string,
  label: string,
  value: string | number,
}

export default defineComponent({
  name: "UiRadioButton",
  emits: [
    'update:modelValue',
  ],
  props: {
    options: {
      type: Array as PropType<RadioButtonI[]>,
      default: () => ([]),
    },
    size: {
      type: String as PropType<'medium'|'large'>,
      default: 'medium',
    },
    modelValue: {},
  },
})
</script>

<style scoped lang="scss">
.slider {
  background-color: white;
  height: fit-content;
  display: grid;
  grid-template-columns: minmax(52px, 1fr) minmax(52px, 1fr);
  border-radius: 4px;
  border: 1px solid #dcdfe5;

  span {
    font-size: 12px;
    line-height: 16px;

    padding: 4px 8px;
    box-sizing: border-box;
    border-radius: 2px;
    text-align: center;
    color: var(--main-color-black);
    user-select: none;
    align-content: center;

    &:hover {
      color: var(--default-blue-color);
      cursor: pointer;
    }

    &.active {
      background-color: #ecf5ff;
      color: var(--default-blue-color);
    }

    &.large {
      padding: 12px 24px;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
    }
  }
}
</style>
