<template lang="pug">
.okpd-tag-input
  cleanable-title.okpd-title(@reset="cleanFilter") КАТЕГОРИИ ОКПД
  ui-multiselect.okpd-select(
    show-count
    ref="okpd"
    placeholder="Введите название или код категории, нажмите enter и выберите из списка"
    v-model="checkedOkpd"
    :icon-right="UiIconNames.Icon_List"
    :options="meta.data || []"
    :loading="meta.info.loading"
    :count="meta.info.count"
    :no-data-description="meta.info.isOver ? `В нашей базе не найдено результатов, удовлетворяющих заданным критериям поиска` : ''"
    @search="getData"
    @click:icon="$emit('show:dialog', true)"
  )
    template(#label="{ option }")
      span
        b {{ option.code + ": "}}
        | {{ option.title }}

  tag-input.tag-input(
    v-model="checkedOkpd"
    placeholder="Категории ОКПД не выбраны"
  )
    template(#tag-label="{ tag, label }")
      span
        b {{ tag.code + ": "}}
        | {{ label }}

</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { getDefaultFilteredEntity } from "@/utils/getters/defaultRequestInfo";
import { useSearchFormCommon } from "@/use/searchForm/useSearchForm";
import { useVModel } from "@vueuse/core";

import TagInput from "@/components/tagInput/TagInput.vue";
import UiMultiselect from "@/components/ui/select/multiselect/UiMultiselect.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

import type { PropType } from "vue";
import type { FilteredEntity } from "@/utils/getters/defaultRequestInfo";

export default defineComponent({
  name: "OkpdTagInput",
  components: {
    TagInput,
    UiMultiselect,
    CleanableTitle,
  },
  emits: [
    'reset',
    'show:dialog',
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
  },
  setup(props, context) {

    const okpd = ref();

    const meta = ref<FilteredEntity>(getDefaultFilteredEntity());

    const checkedOkpd = useVModel(props, 'modelValue', context.emit);

    const {
      resetFieldMeta,
      getFilteredEntity,
    } = useSearchFormCommon();

    function cleanFilter() {
      context.emit('reset', 'okpdCodes')
      meta.value = resetFieldMeta(meta.value)
      okpd.value.clearSearch()
    }

    function onResetMeta() {
      meta.value = resetFieldMeta(meta.value)
    }

    function getData(query: string) {
      getFilteredEntity(meta.value, 'okpd', { title: query })
    }

    return {
      okpd,
      meta,
      checkedOkpd,
      getData,
      cleanFilter,
      onResetMeta,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
.okpd-tag-input {
  height: 100%;
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: var(--max-advanced-filters-width);

  ::v-deep(#tag-editor) {
    height: 100%;
    max-height: 280px;
    min-height: 140px;
  }

  .okpd-select {
    margin-bottom: 16px;
  }
}
</style>
