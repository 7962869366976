<template lang="pug">
.smp-and-sonko
  cleanable-title(@reset="updateNotSmallBusiness(undefined)") СМП и СОНКО
  .filter-card__checkboxes
    .all
      input(
        id="all_smp_and_sonko"
        v-model="notSmallBusinessTmp"
        type="checkbox"
        :value="undefined"
        @change.capture="updateNotSmallBusiness(undefined)"
      )
      label(for="all_smp_and_sonko") Все
    .values
      .smp
        input(
          id="only_smp_and_sonko"
          v-model="notSmallBusinessTmp"
          type="checkbox"
          :value="true"
          @change.capture="updateNotSmallBusiness($event.target.checked ? true : undefined)"
        )
        label(for="only_smp_and_sonko") Только СМП и СОНКО
      .smp
        input(
          id="excluding_smp_and_sonko"
          v-model="notSmallBusinessTmp"
          type="checkbox"
          :value="false"
          @change.capture="updateNotSmallBusiness($event.target.checked ? false : undefined)"
        )
        label(for="excluding_smp_and_sonko") Исключить СМП и СОНКО
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";

import type { PropType } from "vue";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";
import { useVModel } from "@vueuse/core";

export default defineComponent({
  name: "SmpSonko",
  components: {
    CleanableTitle,
  },
  props: {
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  setup(props, context) {

    const searchForm = useVModel(props, 'modelValue', context.emit)
    const notSmallBusinessTmp = computed(() => [searchForm.value.notSmallBusiness]);

    function updateNotSmallBusiness(smp: boolean) {
      searchForm.value.notSmallBusiness = smp
    }

    return {
      notSmallBusinessTmp,
      updateNotSmallBusiness,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/searchForm";

.smp-and-sonko {
  display: flex;
  flex-flow: column;
  width: auto;

  .values {
    display: contents;
  }

  .filter-card__checkboxes {
    @include checkboxes_column;
  }

  label {
    margin: 8px 0 !important;
  }
}
</style>
