<template lang="pug">
company-tag-input.suppliers(
  v-model="modelValue"
  title="Участники"
  mode="suppliers"
  store-variable-name="supplierIds"
  placeholder="Введите ИНН или наименование"
  @reset-field="resetSuppliers"
)
  template(#additional)
    ui-checkbox.search_by_winner(
      id="is-winner-filter"
      v-model="modelValue.winner"
      label="Показать закупки, у которых выбранные участники являются победителями"
      :disabled="!modelValue.supplierIds?.length"
    )
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import CompanyTagInput from "@/components/searchForm/elements/CompanyTagInput.vue";

import type { PropType } from "vue";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";

export default defineComponent({
  name: "SuppliersWrapper",
  components: {
    UiCheckbox,
    CompanyTagInput,
  },
  emits: [
    'reset',
  ],
  props: {
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
  },
  setup(props, context) {

    function resetSuppliers() {
      context.emit('reset', 'supplierIds')
      context.emit('reset', 'winner')
    }

    return {
      resetSuppliers,
    }
  }
})
</script>

<style scoped lang="scss">
.suppliers {
  width: 100%;
  max-width: var(--max-advanced-filters-width);

  .search_by_winner {
    margin-top: 8px;
  }
}
</style>
