import { useRoute } from "vue-router";
import { computed } from "vue";
import { Tabs } from "~/router/tabs";

import type { Ref } from "vue";
import type { BaseSearchFormInterface } from "~/stores/search/SearchFormInterface";

export function useFiltersWarnings(searchForm: Ref<BaseSearchFormInterface>) {

  const route = useRoute();

  const industryMessage = 'Ваши критерии поиска захватывают закупки, опубликованные до 2020 года. Поиск по дереву отраслей работает при установке даты публикации с 01.01.2020. Мы работаем над расширением классификации отраслей, пожалуйста, для корректного поиска воспользуйтесь другими фильтрами или ограничьте дату публикации.'

  // фильтр по отраслям работает только если дата публикации больше 01.01.2020
  const showIndustryMessage = computed(() =>
      (route.name !== Tabs.Search || (searchForm.value?.lotStatusIds?.includes(3) || searchForm.value?.lotStatusIds?.includes(4)))
      && searchForm.value?.industryIds?.length > 0
      && (
        (searchForm.value?.datePublic?.dateFrom && new Date(new Date(searchForm.value?.datePublic?.dateFrom).setHours(0, 0, 0)) < new Date('2020.01.01'))
        || !searchForm.value?.datePublic?.dateFrom
      )
  )

  return {
    industryMessage,
    showIndustryMessage,
  }
}
