<template lang="pug">
.laws
  cleanable-title(@reset="$emit('reset', 'lotLawIds')") ЗАКОНЫ
  .flex-column(v-if="filters.length")
    .margin
      ui-checkbox(as-group v-model="mainFiltersGetter" label="Выбрать все" :indeterminate="isIndeterminate && !mainFiltersGetter" id="selectAllLaws")
      el-dropdown(:hide-on-click="false" :teleported="false" placement="left-start")
        ui-checkbox.other(as-group v-model="otherGetter" :indeterminate="isIndeterminateOthers && !otherGetter" label="Торги РФ" hint-type="info" hint-size="small" id="selectOthersLaws")
        template(#dropdown)
          checkbox-group.others-checkboxes(v-model="modelValue.lotLawIds" :options="otherFilters")
            template(#label="{ option }")
              span
                b {{ option.shortTitle }}
                template(v-if="option.shortTitle !== option.title") : {{ option.title }}
    .margin(:style="`grid-template-rows: repeat(${ (filters.length % 2 ? filters.length + 1 : filters.length) / 2 }, auto);`")
      checkbox-group.filter-card__checkboxes(v-model="modelValue.lotLawIds" :options="filters")
        template(#label="{ option }")
          span
            | {{ option.shortTitle }}
            template(v-if="option.shortTitle !== option.title") : {{ option.title }}
  span.laws-loader(v-else) Загрузка
  warning-message.info(v-if="showWarning" type="warning") У Вас выбраны параметры поиска по завершенным закупкам. В списке законов отображаются только актуальные законы. Пожалуйста, воспользуйтесь Аналитикой для поиска по неактуальным законам.
</template>

<script lang="ts">
import { computed, defineComponent, } from "vue";
import { useVModel } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { Tabs } from "@/router/tabs";

import type { PropType } from "vue";
import type { SearchFormI } from "@/stores/search/SearchFormInterface";
import type { LawI } from "~/stores/manuals/ManualsInterface";
import CheckboxGroup from "@/components/ui/checkbox/CheckboxGroup.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import WarningMessage from "~/components/ui/alert/WarningMessage.vue";

import useManualsStore from "~/stores/manuals/useManualsStore";
import UiIconNames from "~/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "LawsFilter",
  components: {
    WarningMessage,
    UiCheckbox,
    CheckboxGroup,
    CleanableTitle,
  },
  props: {
    modelValue: {
      type: Object as PropType<SearchFormI>,
      default: () => ({}),
    },
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  setup(props, context) {

    const route = useRoute();

    const manualsStore = useManualsStore()
    const { laws, computedLotLawsIds } = storeToRefs(manualsStore)

    const searchForm = useVModel(props, 'modelValue', context.emit);

    const showWarning = computed(() => route.name === Tabs.Search && ([3, 4].some(e => searchForm.value?.lotStatusIds?.includes(e)) || !searchForm.value?.lotStatusIds?.length))
    const showOnlyActual = computed(() => route.name === Tabs.Search)

    function getSortId(a: LawI) {
      if (!a.isActual) return 100 + a.id
      if (a.id === 5) return 2.5
      if (a.id === 7) return 2.6
      if (a.id === 3) return 100
      return a.id
    }

    function sortLaws(a: LawI, b: LawI) {
      return getSortId(a) - getSortId(b)
    }

    const filters = computed(() => laws.value?.filter(e => e.id < 8 && (!showOnlyActual.value || e.isActual))?.sort(sortLaws) || [])
    const otherFilters = computed(() => laws.value?.filter(e => e.id >= 8 && (!showOnlyActual.value || e.isActual)) || [])

    const additionalFiltersIds = computed(() => otherFilters.value.map(e => e.id));

    function isLawsIdsIncludesId(id: number) {
      return searchForm.value?.lotLawIds?.includes(id);
    }

    const otherGetter = computed({
      get: () => additionalFiltersIds.value.every(isLawsIdsIncludesId),
      set(value: boolean) {
        searchForm.value.lotLawIds =
          value
            ? [...new Set([...searchForm.value.lotLawIds, ...additionalFiltersIds.value])]
            : searchForm.value?.lotLawIds?.filter(id => !additionalFiltersIds.value.includes(id))
      }
    })

    const mainFiltersGetter = computed({
      get: () => computedLotLawsIds.value.every(isLawsIdsIncludesId),
      set(value: boolean) {
        searchForm.value.lotLawIds = value ? computedLotLawsIds.value : []
      }
    })

    const isIndeterminate = computed(() => computedLotLawsIds.value?.some(isLawsIdsIncludesId))
    const isIndeterminateOthers = computed(() => additionalFiltersIds.value.some(isLawsIdsIncludesId))

    return {
      showOnlyActual,
      showWarning,
      filters,
      otherFilters,
      otherGetter,
      mainFiltersGetter,
      isIndeterminate,
      isIndeterminateOthers,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/searchForm";

.laws {
  display: flex;
  flex-flow: column;

  .filter-card__checkboxes {
    display: contents !important;
  }

  .flex-column {
    margin-top: 8px;
  }

  .laws-option {
    display: flex;
    flex-flow: row;
    gap: 4px;
    height: fit-content;
  }

  .margin {
    //margin-top: 8px;
    display: grid;
    grid-template-columns: 6fr 4fr;
    //grid-template-rows: repeat(3, auto);
    grid-auto-flow: column;
    column-gap: 16px;
  }

  .other {
    outline: none !important;
    width: 100%;

    :deep(label) {
      display: flex;
      align-items: center;
    }

    :deep(.hint-icon) {
      color: var(--default-blue-color) !important;
    }
  }

  .others-checkboxes {
    width: 420px;
    display: flex;
    flex-flow: column;
    padding: 8px 16px;
  }

  .hint-icon {
    color: var(--main-red-color);
  }

  .laws-loader {
    font-size: 13px;
    line-height: 16px;
    color: var(--main-placeholder-color);
    align-self: center;
  }
}

.info {
  margin-top: 12px;
}

.warning-content {
  max-width: 260px;
}
</style>
