<template lang="pug">
.keywords-filter
  radio-group.radio-group-wrapper(v-if="!simple" v-model="mode" :options="radioGroup")

  .words-panel(v-if="!simple && isExpertSearch")
    cleanable-title(@reset="$emit('reset', 'lotsSearch.expertSearch')")
      span ПРОФЕССИОНАЛЬНЫЙ ПОИСК
      template(#additional)
        keywords-warning(type="expert")
        icon-button.fullscreen(:icon="UiIconNames.Icon_FullScreen" :size="14" tooltip-title="Открыть на весь экран" @click="openFullScreen")
    form-textarea.expert-search(v-model="modelValue.lotsSearch.expertSearch" placeholder="Введите запрос" :spellcheck="false")

  .words-panel(v-if="!isExpertSearch || simple")
    cleanable-title(@reset="$emit('reset', 'lotsSearch.keywords')")
      span КЛЮЧЕВЫЕ СЛОВА
      template(#additional)
        keywords-warning(type="simple")
    tag-input.search_panel_frame.tags-keywords(
      writable
      v-model="modelValue.lotsSearch.keywords"
      placeholder="Введите ключевые слова или словосочетания (через запятую)"
      :disabled="!!modelValue.lotsSearch.expertSearch"
    )

  .words-panel(v-if="!isExpertSearch")
    cleanable-title(@reset="$emit('reset', 'lotsSearch.exceptionWords')") СЛОВА ИСКЛЮЧЕНИЯ
    tag-input.search_panel_frame.tags-exceptions(
      writable
      v-model="modelValue.lotsSearch.exceptionWords"
      placeholder="Введите слова или словосочетания исключения (через запятую)"
      :disabled="!!modelValue.lotsSearch.expertSearch"
    )

  warning-message(v-if="!simple && (isExpertSearch || !!modelValue.lotsSearch.expertSearch)" type="warning")
    span(v-if="!isExpertSearch && !!modelValue.lotsSearch.expertSearch") Для доступа к фильтру, очистите введенное значение в режиме профессионального поиска.
    span(v-if="isExpertSearch") В режиме профессионального поиска Вы можете самостоятельно писать сложные запросы. Имейте в виду, что ключевые слова и слова исключения, введенные в режиме "поиска по словам", игнорируются.
    router-link.link(:to="{ name: Tabs.Training.Filters }" target="_blank") {{' '}} Подробнее.

expert-search-dialog(v-if="showFullScreen" v-model:show="showFullScreen" v-model:expert-search="modelValue.lotsSearch.expertSearch")
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useVModel } from "@vueuse/core";

import TagInput from "@/components/tagInput/TagInput.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";
import RadioGroup from "@/components/ui/radio/RadioGroup.vue";
import FormTextarea from "@/components/ui/form/input/FormTextarea.vue";
import IconButton from "@/components/ui/button/IconButton.vue";
import ExpertSearchDialog from "@/components/searchForm/dialogs/ExpertSearchDialog.vue";
import WarningMessage from "~/components/ui/alert/WarningMessage.vue";

import { Tabs } from "@/router/tabs";
import type { PropType } from "vue";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import UiWarning from "~/components/ui/tooltip/UiWarning.vue";
import AccessedLink from "~/components/ui/links/AccessedLink.vue";
import KeywordsWarning from "~/components/searchForm/elements/KeywordsWarning.vue";

type WordsMode = 'expertSearch'|'wordSearch';

export default defineComponent({
  name: "KeywordsForm",
  components: {
    KeywordsWarning,
    AccessedLink,
    UiWarning,
    WarningMessage,
    ExpertSearchDialog,
    IconButton,
    FormTextarea,
    RadioGroup,
    TagInput,
    CleanableTitle,
  },
  props: {
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
    simple: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  setup(props, context) {

    const searchForm = useVModel(props, 'modelValue', context.emit)

    const mode = ref<WordsMode>(!props.simple && searchForm.value?.lotsSearch.expertSearch ? 'expertSearch' : 'wordSearch')
    const isExpertSearch = computed(() => mode.value === 'expertSearch')

    const showFullScreen = ref(false);

    const radioGroup = [
      { id: 'wordSearch', title: 'Поиск по словам' },
      { id: 'expertSearch', title: 'Профессиональный поиск' },
    ]

    function openFullScreen() {
      showFullScreen.value = true;
    }

    return {
      mode,
      radioGroup,
      isExpertSearch,
      showFullScreen,
      openFullScreen,
      Tabs,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
.keywords-filter {
  display: flex;
  flex-flow: column;
  gap: 16px;
  width: 100%;
  max-width: var(--max-advanced-filters-width);
  height: 100%;

  .radio-group-wrapper {
    width: fit-content;
    gap: 24px;

    ::v-deep(.radio-item) {
      white-space: nowrap;
    }
  }

  ::v-deep(.radio-group) {
    flex-flow: row;

    .radio-item {
      flex: 1;
    }
  }

  .words-panel {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-flow: column;

    .search_panel_frame {
      box-shadow: none !important;
      height: 100% !important;
      min-height: 120px;
      max-height: 160px;
    }
  }

  .expert-search-label {
    display: flex;
    flex-flow: row;
    gap: 4px;
    align-items: center;
  }

  .fullscreen {
    color: var(--main-color-blue);
  }

  .expert-search {
    height: 100%;

    :deep(.ui-textarea) {
      height: 100%;
    }

    :deep(.base-textarea) {
      resize: none;
      height: 100%;
      max-height: none;
      padding: 11px 14px;
    }
  }

  .link {
    font-weight: bold;
    cursor: pointer;
  }
}

.warning-content {
  display: flex;
  flex-flow: column;
  gap: 6px;
  max-width: 420px;
  padding: 4px;

  .label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
}

.example-tag {
  color: #409eff;
  background-color: #ecf5ff;
  border-radius: 2px;
  padding: 2px 4px;
  margin-left: 8px;

  &.margin-right {
    margin-right: 8px;
  }
}
</style>
