<template lang="pug">
.category-okpd
  okpd-tag-input(
    v-model="modelValue.okpdCodes"
    @show:dialog="showOkpdDialog = $event"
    @reset="$emit('reset', $event)"
  )

okpd-dialog(
  v-model:show="showOkpdDialog"
  v-model:searchForm="modelValue"
)
</template>

<script lang="ts">
import { defineAsyncComponent, ref, defineComponent } from "vue";
import OkpdTagInput from "@/components/searchForm/elements/okpd/OkpdTagInput.vue";

import type { PropType } from "vue";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";

export default defineComponent({
  name: "OkpdWrapper",
  components: {
    OkpdTagInput,
    OkpdDialog: defineAsyncComponent(() => import("@/components/searchForm/dialogs/OkpdDialog.vue")),
  },
  props: {
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  setup() {

    const showOkpdDialog = ref(false);

    return {
      showOkpdDialog,
    };
  }
})
</script>

<style scoped lang="scss">
.category-okpd {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: var(--max-advanced-filters-width);
}
</style>
