<template lang="pug">
.tag-input-wrapper
  cleanable-title(@reset="cleanFilter") {{ title }}
  ui-multiselect.tag-input-wrapper__selector(
    ref="select"
    v-model="modelValue[storeVariableName]"
    :placeholder="placeholder"
    :options="meta.data || []"
    :loading="meta.info.loading"
    :no-data-description="meta.info.isOver ? `В нашей базе нет данных по ${ mode === 'customers' ? 'заказчикам' : 'поставщикам' }, удовлетворяющим заданным критериям поиска` : ''"
    @search="getData"
    @reset:options="onResetMeta"
  )
    template(#label="{ option }")
      span
        | {{ option.title }}
        template(v-if="option.inn") , ИНН:
        b(v-if="option.inn") {{ ' ' + option.inn }}
        template(v-if="option.kpp") , КПП:
        b(v-if="option.kpp") {{ ' ' + option.kpp }}

  tag-input(
    v-model="modelValue[storeVariableName]"
    :placeholder="title + ' не выбраны'"
  )
  slot(name="additional")
</template>

<script lang="ts">
import type { PropType } from "vue";
import type { TagWrapperMode } from "@/stores/search/SearchFormInterface";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";
import type { FilteredEntity } from "@/utils/getters/defaultRequestInfo";

import TagInput from "@/components/tagInput/TagInput.vue";
import UiMultiselect from "@/components/ui/select/multiselect/UiMultiselect.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";

import { ref, defineComponent } from "vue";
import { useSearchFormCommon } from "@/use/searchForm/useSearchForm";
import { getDefaultFilteredEntity } from "@/utils/getters/defaultRequestInfo";

export default defineComponent({
  name: "TagInputWrapper",
  components: {
    TagInput,
    UiMultiselect,
    CleanableTitle,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    mode: {
      type: String as PropType<TagWrapperMode>,
      default: '',
    },
    storeVariableName: {
      type: String as PropType<'customerIds'|'supplierIds'>,
      default: '',
    },
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
  },
  emits: [
    'resetField',
    'update:modelValue',
  ],
  setup(props, { emit }) {

    const {
      getFilteredEntity,
      resetFieldMeta,
    } = useSearchFormCommon();

    const select = ref();
    const meta = ref<FilteredEntity>(getDefaultFilteredEntity());

    function cleanFilter() {
      emit('resetField')
      meta.value = resetFieldMeta(meta.value)
      select.value.clearSearch();
    }

    function onResetMeta() {
      meta.value = resetFieldMeta(meta.value)
    }

    function getData(query: string) {
      getFilteredEntity(meta.value, props.mode,
        { search: { keywords: [query], companyIds: [] }}
      )
    }

    // function onClose() {
    //   select.value.clearSearch();
    //   meta.value = getDefaultFilteredEntity();
    // }

    return {
      select,
      meta,
      getData,
      cleanFilter,
      onResetMeta,
    }
  }
})
</script>

<style scoped lang="scss">
.tag-input-wrapper {
  width: 100%;
  max-width: var(--max-advanced-filters-width);
}

.tag-input-wrapper__selector {
  margin-bottom: 16px;
}
</style>
