<template lang="pug">
ui-warning(:placement="type === 'delivery' ? 'bottom-end' : 'bottom-start'")
  .warning-content(v-if="type === 'simple'")
    span.label Ключевые слова и слова исключения
    span Введите ключевые слова и слова исключения через запятую для поиска по названию тендера и объектам закупки.
    span Результаты поиска будут содержать закупки, в которых нашлось хотя бы одно введенное слово. Закупки, в которых встречаются слова исключения, показаны не будут.
    span При использовании словосочетаний, будут показаны закупки, в которых встречаются слова из введенной фразы (не обязательно последовательно).
    span.example Пример:
      span.example-tag термометр
      span.example-tag медицинский материал
  .warning-content(v-if="type === 'expert'")
    span.label Построение профессиональных запросов
    span В режиме профессионального поиска Вы можете самостоятельно писать сложные запросы. Имейте в виду, что ключевые слова и слова исключения, введенные в режиме "поиска по словам", <b>игнорируются</b>.
    span.example Примеры:
    ul
      li
        span
          span Запрос
          span.example-tag.margin-right молоко & хлеб
          span найдет все закупки, где найдется оба слова. Запрос
          span.example-tag.margin-right молоко | хлеб
          span найдет все закупки, где найдется хотя бы одно слово.
      li
        span
          span Запрос
          span.example-tag.margin-right медици:*
          span найдет все закупки, где встречаются слова, начинающиеся с префикса <i>медици</i>.
      li
        span
          span Для поиска слова в наименовании тендера, используйте латинскую букву A. Для объектов закупки B, кодов окпд С, а для мест поставки - D:
          span.example-tag.margin-right (молоко:A | хлеб:*AB) & (56.29:*C)
      li
        span
          span Отфильтровать нежелательные слова можно символом !:
          span.example-tag.margin-right ((поставка:A & бетон:*AB) & !(услуг:*))
    span
      | Более точную информацию по построению профессиональных запросов читайте
      |
      accessed-link(:name="Tabs.Training.Filters" target="_blank") здесь.
  .warning-content(v-if="type === 'delivery'")
    span.label Фильтр по месту выполнение работ (оказания услуг, поставки товара)
    span Укажите регион, населенный пункт или улицу. Вы можете перечислить несколько вариантов через запятую.
    span.example Пример:
      span.example-tag Новосибирск
      span.example-tag ул. Центральная
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Tabs } from "@/router/tabs";
import type { PropType } from "vue";

import AccessedLink from "~/components/ui/links/AccessedLink.vue";
import UiWarning from "~/components/ui/tooltip/UiWarning.vue";

export default defineComponent({
  name: "KeywordsWarning",
  components: {
    UiWarning,
    AccessedLink,
  },
  props: {
    type: {
      type: String as PropType<'simple'|'expert'|'delivery'>,
      default: 'simple',
    }
  },
  setup() {
    return {
      Tabs,
    }
  }
})
</script>

<style scoped lang="scss">
.warning-content {
  display: flex;
  flex-flow: column;
  gap: 6px;
  max-width: 420px;
  padding: 4px;

  .label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
}

.example-tag {
  color: #409eff;
  background-color: #ecf5ff;
  border-radius: 2px;
  padding: 2px 4px;
  margin-left: 8px;

  &.margin-right {
    margin-right: 8px;
  }
}
</style>
