<template lang="pug">
.ex(:class="!teleported && '_relative'")
  .base-select(
    ref="reference"
    :class="disabled && '_disabled'"
    @mouseover="!disabled && clearable && isSelected && (isHovered = true)"
    @mouseleave="isHovered = false"
    @click.stop="onCLickInput(true)"
  )
    .base-select__row(:class="disabled && '_disabled'")
      slot(name="select-row")
        input.base-select__input(
          v-model="searchText"
          :disabled="disabled"
          :placeholder="placeholder"
          @input="onFilter"
          @keyup.enter="onEnter"
        )
      icon-button(
        :size="16"
        :disabled="disabled"
        :icon="iconRight ? iconRight : (clearable && isSelected && isHovered ? UiIconNames.Icon_CircleClose : (showFoundOptions ? UiIconNames.Chevron_Up : UiIconNames.Chevron_Down))"
        @click.stop="iconRight ? onClickIcon() : clearable && isSelected ? resetSearchText() : onCLickInput()"
      )

  .base-select__dropdown(v-if="!disabled && showFoundOptions" ref="floating" :style="{...floatingStyles, width: popperWidth }")
    .loader(v-if="loading")
      span Загрузка
      mini-loader
    .options(v-else-if="!isEmpty")
      slot(name="selector")
    .no-data(v-else)
      slot(name="emptySelector")
        span Список пуст
        span.error(v-if="emptySearch") Пустой запрос. {{ placeholder }}
        span.description(v-else-if="noDataDescription") {{ noDataDescription }}

</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useDebounceFn, useElementSize, onClickOutside } from "@vueuse/core";
import { autoUpdate, shift, useFloating } from "@floating-ui/vue";

import IconButton from "@/components/ui/button/IconButton.vue";
import MiniLoader from "@/components/ui/loader/MiniLoader.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "BaseSelect",
  components: {
    MiniLoader,
    IconButton,
  },
  emits: [
    "search",
    "click:icon",
    "reset:options",
    "click:clear",
  ],
  props: {
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Очистить селектор - при выбранном значении при наведении появится иконка circle-close
     */
    clearable: {
      type: Boolean,
      default: false,
    },
    /**
     * Выбрано ли значение в селекторе
     */
    isSelected: {
      type: Boolean,
      default: false,
    },
    /**
     * isEmpty - есть ли данные для селектора
     */
    isEmpty: {
      type: Boolean,
      default: false,
    },
    /**
     * loading - загрузка значений
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * кастомная иконка справа внутри инпута
     */
    iconRight: {
      type: String,
      default: '',
    },
    /**
     * подпись под "Список пуст"
     */
    noDataDescription: {
      type: String,
      default: '',
    },
    teleported: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const searchText = ref("");
    const isHovered = ref(false);

    const showFoundOptions = ref(false);
    const emptySearch = ref(false);

    const reference = ref(null);
    const floating = ref(null);

    const { floatingStyles } = props.teleported
      ? useFloating(reference, floating, { placement: 'bottom-start', middleware: [shift()], whileElementsMounted: autoUpdate })
      : {};

    const { width } = useElementSize(reference, 0, { box: 'border-box' });

    const popperWidth = computed(() => width.value ? `${width.value}px` : 'auto');

    function resetSearchText() {
      searchText.value = ""
      context.emit('click:clear')
    }

    function onClickIcon() {
      showFoundOptions.value = false
      context.emit('click:icon')
    }

    function onCLickInput(close? : boolean) {
      if (!props.disabled) {
        showFoundOptions.value = close === undefined ? !showFoundOptions.value : close;
      }
    }

    onClickOutside(floating, () => {
      onCLickInput(false)
    }, { ignore: [reference] })

    function onEnter() {
      if (searchText.value) {
        emptySearch.value = false;
        context.emit('search', searchText.value)
      } else {
        emptySearch.value = true;
        context.emit('reset:options')
      }
    }

    const onFilter = useDebounceFn(onEnter, 400);

    return {
      showFoundOptions,
      popperWidth,
      reference,
      floating,
      floatingStyles,
      searchText,
      isHovered,
      emptySearch,
      onEnter,
      onClickIcon,
      onCLickInput,
      resetSearchText,   // для родительского компонента
      onFilter,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/colors";

.ex._relative {
  position: relative;
}

.base-select {
  width: 100%;
  font-size: 14px;
  line-height: 16px;

  &._disabled {
    .base-select__row {
      color: $input-color-txt-disabled;
      border: 1px solid $input-color-border-disabled;
      background: $input-color-bg-disabled;
    }

    ::v-deep(.icon-button) {
      color: $input-color-txt-disabled;
    }
  }

  .base-select__input {
    width: 100%;
    height: 16px;
    border-radius: var(--input-border-radius);
    box-shadow: none;
    border: none;
    outline: none;
    padding: 0;
    color: $input-color-txt;
    background-color: inherit;
  }

  .base-select__input::placeholder {
    color: $input-color-txt-placeholder;
  }

  .base-select__input:focus::placeholder {
    color: $input-color-txt-placeholder;
  }

  .base-select__row {
    display: flex;
    flex-flow: row;
    gap: 4px;
    padding: 7px 16px;

    border: 1px solid $input-color-border;
    border-radius: var(--input-border-radius);
    outline: none;
    box-sizing: border-box;

    &:not(._disabled) {
      cursor: pointer;
    }

    &:hover {
      border-color: $input-color-border-hover;
    }
  }
}

.base-select__dropdown {
  position: absolute;
  overflow: auto;
  z-index: 10;
  max-height: 300px;
  padding: 8px 0 8px 0;
  background-color: white;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid var(--main-color-grey-popup);
  box-sizing: border-box;
  box-shadow: var(--dialog-border-shadow);
  border-radius: 4px;
  margin-top: 4px;

  .no-data {
    display: flex;
    flex-flow: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    color: var(--main-text-color);
    margin: 24px;

    font-size: 12px;
    line-height: 16px;
    text-align: center;

    .description {
      color: var(--main-placeholder-color);
    }

    .error {
      color: var(--main-red-color);
    }
  }

  .loader {
    display: flex;
    flex-flow: row;
    gap: 8px;
    justify-content: center;
    align-items: center;
    color: var(--main-placeholder-color);
    margin: 24px;
  }
}
</style>
