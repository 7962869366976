<template lang="pug">
.date-open
  cleanable-title(@reset="$emit('reset', 'dateApplication')") ДАТА ПОДАЧИ ЗАЯВКИ
  date-filter-card.date-open__card(
    future-ranges
    date-type="dateApplication"
    :date-value="modelValue.dateApplication"
    @update:date="updateDate"
  )
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "@vueuse/core";

import DateFilterCard from "@/components/searchForm/elements/DateFilterCard.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";

import type { PropType } from "vue";
import type { SearchFormI } from "@/stores/search/SearchFormInterface";

export default defineComponent({
  name: "DateOpen",
  components: {
    DateFilterCard,
    CleanableTitle,
  },
  props: {
    modelValue: {
      type: Object as PropType<SearchFormI>,
      default: () => ({}),
    },
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  setup(props, context) {

    const searchForm = useVModel(props, 'modelValue', context.emit)

    function updateDate(event: {field: 'dateApplication', value: any}) {
      searchForm.value[event.field] = event.value
    }

    return {
      updateDate,
    };
  },
});
</script>

<style scoped lang="scss">
.date-open__card {
  margin-top: 16px;
}
</style>
