import { useSearchFormCommon } from "@/use/searchForm/useSearchForm";
import type { Ref } from "vue";
import type { FilteredEntity } from "@/utils/getters/defaultRequestInfo";
import type { SimpleSiteInterface, SiteGroupI, TagI } from "@/stores/manuals/ManualsInterface";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";

export function useLawSite(searchForm: Ref<BaseSearchFormInterface>) {

  const {
    getFilteredEntity,
    resetFieldMeta,
  } = useSearchFormCommon();

  const noSitesLabel = 'Без площадок';
  const noSitesHint = 'При выборе данного параметра, Вам будут показаны только закупки, у которых не указана площадка проведения торгов. Если Вы выберете дополнительные площадки/группы, они будут показаны наряду с закупками без площадок';

  function customTagClass<T extends TagI>(data: T) {
    if (data.id === 'without-sites') return "tag_group";
    if (data.code) return "tag_group";
    return null;
  }

  // очистить группы площадок
  function deleteAllGroups() {
    searchForm.value.lotAuctionSitesGroups = []
    searchForm.value.withoutLotAuctionSite = false
  }

  // очистить площадки
  function deleteAllSites() {
    searchForm.value.lotAuctionSitesIds = []
  }

  function deleteTag(field: 'lotAuctionSitesGroups'|'lotAuctionSitesIds', id: number|string, key = 'id') {
    searchForm.value[field] = (searchForm.value[field] ?? []).filter((t: any) => t[key] !== id);
  }

  // удалить тег группы или площадки
  function deleteSiteTag(tag: SimpleSiteInterface|SiteGroupI) {
    if (tag.id === 'without-sites') searchForm.value.withoutLotAuctionSite = false
    else if (tag.code) deleteTag('lotAuctionSitesGroups', tag.code, 'code');
    else deleteTag('lotAuctionSitesIds', tag.id);
  }

  function getData(meta: FilteredEntity, query: string) {
    getFilteredEntity(meta, 'sites', {
      title: query,
      link: query,
    })
  }

  return {
    noSitesLabel,
    noSitesHint,
    deleteAllGroups,
    deleteAllSites,
    deleteSiteTag,
    getData,
    resetFieldMeta,
    customTagClass,
  };
}
