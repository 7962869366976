<template lang="pug">
company-tag-input.customers(
  v-model="modelValue"
  title="Заказчики"
  mode="customers"
  store-variable-name="customerIds"
  placeholder="Введите ИНН или наименование"
  @reset-field="resetCustomers"
)
  template(#additional)
    ui-checkbox.search_by_hosting(
      id="by_hosting_organization"
      v-model="modelValue.needOrganizationResponsibleIds"
      label="Искать также по Размещающим организациям"
      :disabled="!modelValue.customerIds?.length"
    )
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CompanyTagInput from "@/components/searchForm/elements/CompanyTagInput.vue";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";

import type { PropType } from "vue";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";

export default defineComponent({
  name: "CustomersWrapper",
  components: {
    UiCheckbox,
    CompanyTagInput,
  },
  emits: [
    'reset',
  ],
  props: {
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
  },
  setup(props, context) {

    function resetCustomers() {
      context.emit('reset', 'customerIds')
      context.emit('reset', 'needOrganizationResponsibleIds')
    }

    return {
      resetCustomers,
    }
  }
})
</script>

<style scoped lang="scss">
.customers {
  width: 100%;
  max-width: var(--max-advanced-filters-width);

  .search_by_hosting {
    margin-top: 8px;
  }
}
</style>
