<template lang="pug">
.button-group
  .warning(v-if="showWarning && warningType")
    ui-icon.icon(:icon="UiIconNames.Icon_Info")
    span(v-if="warningType === 'newSearch'") Выбраны новые фильтры, не забудьте обновить результаты поиска
    span(v-else) В шаблон внесены изменения. Не забудьте сохранить их

  ui-button(
    v-if="showCopyButton"
    type="secondary"
    @click="$emit('form:extend')"
  ) Перенести фильтры в расширенную форму поиска

  .right
    template(v-if="enableTemplates && !enableOnlyExpertTemplates")
      .existing-template(v-if="editMode")
        ui-button.no-wrap(type="secondary" @click="$emit('save:template')") Сохранить как новый шаблон
        ui-button.no-wrap(type="secondary" style="justify-content: center" @click="$emit('form:exit')") Выйти без сохранения
      ui-button(v-else-if="tab === 'LotsListSearch' && !roleReadOnly" type="secondary" @click="$emit('save:template')") Сохранить шаблон

    ui-button.no-wrap(type="secondary" @click="$emit('form:reset')") Сбросить все фильтры
    icon-button(
      v-if="enableTemplates && !enableOnlyExpertTemplates && editMode"
      as-button
      button-type="secondary"
      tooltip-title="Поиск без сохранения фильтров"
      :icon="UiIconNames.Icon_Search"
      @click="$emit('start:searchOnly')"
    )

    ui-button.main-button(
      v-if="editMode"
      size="large"
      :icon-left="UiIconNames.Icon_Search"
      @click="$emit('save:changes')"
    ) Сохранить

    ui-button.main-button(
      v-else-if="tab === 'LotsListSearch'"
      size="large"
      :icon-left="UiIconNames.Icon_Search"
      @click="$emit('start:search')"
    ) Поиск

    ui-button.main-button(
      v-else
      size="large"
      :icon-left="UiIconNames.Landing_DataAnalysis"
      @click="$emit('start:analyse')"
    ) Анализ

</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import type { PropType } from 'vue';
import type { TabMode } from "@/stores/search/SearchFormInterface";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import IconButton from "@/components/ui/button/IconButton.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "SearchFormButtons",
  components: {
    IconButton,
    UiIcon,
    UiButton,
  },
  emits: [
    'start:search',        // начать поиск
    'start:searchOnly',    // начать поиск без сохранения фильтров (при  editMode)
    'start:analyse',       // начать анализ
    'save:changes',        // обновить шаблон
    'save:template',       // сохранить шаблон
    'form:reset',          // сбросить все фильтры
    'form:exit',           // выйти без сохранения
    'form:extend',         // перенести результаты в расширенную форму поиска
  ],
  props: {
    tab: {
      type: String as PropType<TabMode>,
      default: 'LotsListSearch',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    showWarning: {
      type: Boolean,
      default: false,
    },
    warningType: {
      type: String,
      default: '',
    },
    showCopyButton: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

    const {
      enableTemplates,
      enableOnlyExpertTemplates,
      roleReadOnly,
    } = useUserAccess()

    return {
      UiIconNames,
      roleReadOnly,
      enableTemplates,
      enableOnlyExpertTemplates,
    }
  }
})
</script>

<style scoped lang="scss">
.button-group {
  margin: 0 44px 16px 44px;
  display: flex;
  flex-flow: row;
  gap: 32px;
  align-items: flex-end;

  .warning {
    display: flex;
    flex-flow: row;
    gap: 4px;

    font-size: 13px;
    line-height: 18px;
    color: var(--main-red-color);

    .icon {
      padding: 1px 0;
    }
  }

  .right {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    margin-left: auto;
  }

  .existing-template {
    display: flex;
    flex-flow: column;
    gap: 8px;
  }

  .main-button {
    padding: 13px 16px;
  }

  .no-wrap {
    white-space: nowrap;
  }
}
</style>
