<template lang="pug">
.zmo-filter
  cleanable-title(@reset="updateZMO(undefined)") ЗМО
  .filter-card__checkboxes
    input(
      id="all_zmo"
      v-model="isZMOtmp"
      :value="undefined"
      type="checkbox"
      @change.capture="updateZMO(undefined)"
    )
    label(for="all_zmo") Все
    input(
      id="only_zmo"
      v-model="isZMOtmp"
      :value="true"
      type="checkbox"
      @change.capture="updateZMO($event.target.checked ? true : undefined)"
    )
    label(for="only_zmo") Только Закупки малого объема
    input(
      id="exclude_zmo"
      v-model="isZMOtmp"
      :value="false"
      type="checkbox"
      @change.capture="updateZMO($event.target.checked ? false : undefined)"
    )
    label(for="exclude_zmo") Исключить Закупки малого объема
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useVModel } from "@vueuse/core";

import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";
import type { PropType } from "vue";
import type { SearchFormI } from "@/stores/search/SearchFormInterface";

export default defineComponent({
  name: "LotZMO",
  components: {
    CleanableTitle,
  },
  props: {
    modelValue: {
      type: Object as PropType<SearchFormI>,
      default: () => ({}),
    },
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  setup(props, context) {

    const searchForm = useVModel(props, 'modelValue', context.emit)

    const isZMOtmp = computed<any[]>(() => [props.modelValue.isZMO]);

    function updateZMO(zmo: boolean) {
      searchForm.value.isZMO = zmo
    }

    return {
      isZMOtmp,
      updateZMO,
    };
  },
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/searchForm";

.zmo-filter {
  display: flex;
  flex-flow: column;

  label {
    margin: 8px 0 !important;
  }

  .filter-card__checkboxes {
    @include checkboxes_column;
  }
}
</style>
