<template lang="pug">
.date-public
  cleanable-title(@reset="$emit('reset', 'datePublic')") ДАТА ПУБЛИКАЦИИ
  date-filter-card.date-public__card(
    date-type="datePublic"
    :date-value="modelValue.datePublic"
    @update:date="updateDate"
  )
  warning-message.info(v-if="showMessage" type="warning") {{ industryMessage }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "@vueuse/core";
import { useFiltersWarnings } from "~/use/search/useSearchWarnings";
import { useRoute } from "vue-router";

import DateFilterCard from "@/components/searchForm/elements/DateFilterCard.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";
import WarningMessage from "~/components/ui/alert/WarningMessage.vue";

import type { PropType } from "vue";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";
import { Tabs } from "@/router/tabs";

export default defineComponent({
  name: "DatePublic",
  components: {
    WarningMessage,
    DateFilterCard,
    CleanableTitle,
  },
  props: {
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  setup(props, context) {

    const route = useRoute()
    const searchForm = useVModel(props, 'modelValue', context.emit)

    const { industryMessage, showIndustryMessage } = useFiltersWarnings(searchForm);
    const showMessage = computed(() => route.name === Tabs.Search && showIndustryMessage.value)

    function updateDate(event: {field: 'datePublic', value: any}) {
      searchForm.value[event.field] = event.value
    }

    return {
      showMessage,
      industryMessage,
      updateDate,
    };
  },
});
</script>

<style scoped lang="scss">
.date-public__card {
  margin-top: 16px;
}

.info {
  margin-top: 12px;
}
</style>
