<template lang="pug">
ui-dialog(
  modal
  closable
  width="90vh"
  :visible-prop="show"
  @content:hide="hide"
)
  template(#header) Экспертный поиск
  template(#content)
    form-textarea.expert-dialog(v-model="innerSearch" placeholder="Введите запрос" :spellcheck="false")
  template(#actions)
    ui-button(type="secondary" @click="hide") Отменить
    ui-button(@click="confirm") Применить
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import UiDialog from "@/components/ui/dialog/UiDialog.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import FormTextarea from "@/components/ui/form/input/FormTextarea.vue";

export default defineComponent({
  name: "ExpertSearchDialog",
  components: {
    FormTextarea,
    UiDialog,
    UiButton,
  },
  emits: [
    "update:show",
    "update:expertSearch",
  ],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    expertSearch: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {

    const innerSearch = ref(props.expertSearch);

    function hide() {
      emit('update:show', false);
      innerSearch.value = props.expertSearch;
    }

    function confirm() {
      emit('update:expertSearch', innerSearch.value);
      hide()
    }

    return {
      hide,
      confirm,
      innerSearch,
    }
  }
})
</script>

<style scoped lang="scss">
.expert-dialog {
  height: 520px;
  max-height: 60vh;

  :deep(.ui-textarea) {
    height: 100%;
  }

  :deep(.base-textarea) {
    resize: none;
    height: 100%;
    max-height: none;
    padding: 11px 14px;
    font-size: 16px;
    line-height: 22px;
  }
}
</style>
