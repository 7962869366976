<template lang="pug">
.company-kpp
  cleanable-title(@reset="cleanKpp") КПП
  .company-kpp__row
    base-input(
      v-model="modelValue.kpp"
      style="width: 280px"
      placeholder="Укажите КПП"
      type="number"
      :disabled="modelValue.needAllFilials"
    )
    ui-checkbox(
      id="display-all-branches"
      label="Выводить все филиалы"
      :model-value="modelValue.needAllFilials"
      @update:modelValue="setDefaultKpp"
    )
</template>

<script lang="ts">
import { computed, defineComponent, inject } from "vue";
import { useVModel } from "@vueuse/core";

import BaseInput from "@/components/ui/base/BaseInput.vue";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";

import type { PropType } from "vue";
import type { TabMode } from "@/stores/search/SearchFormInterface";
import type { AnalyticCustomerFormI, AnalyticSuppliersFormI } from "@/stores/search/SearchFormInterface";
import useAnalyticsSuppliersStore from "@/stores/analytics/useAnalyticsSuppliersStore";
import useAnalyticsCustomersStore from "@/stores/analytics/useAnalyticsCustomersStore";

export default defineComponent({
  name: "KppFilter",
  components: {
    BaseInput,
    UiCheckbox,
    CleanableTitle,
  },
  props: {
    id: {
      type: String,
    },
    modelValue: {
      type: Object as PropType<AnalyticCustomerFormI | AnalyticSuppliersFormI>,
      default: () => ({}),
    },
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  setup(props, context) {

    const type: TabMode = inject("type");

    const searchForm = useVModel(props, 'modelValue', context.emit);

    const suppliersStore = useAnalyticsSuppliersStore()
    const customersStore = useAnalyticsCustomersStore()

    const defaultKpp = computed<number>(() => {
        switch (type) {
          case 'AnalyseSuppliers': return suppliersStore.selectedCompany.kpp;
          case 'AnalyseCustomers': return customersStore.selectedCompany.kpp;
        }
      }
    );

    function cleanKpp() {
      searchForm.value.needAllFilials = true;
      searchForm.value.kpp = defaultKpp.value;
    }

    function setDefaultKpp(checked: boolean) {
      searchForm.value.needAllFilials = checked;
      if (checked) searchForm.value.kpp = defaultKpp.value;
    }

    return {
      cleanKpp,
      setDefaultKpp,
    };
  },
});
</script>

<style scoped lang="scss">
.company-kpp__row {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 16px;
}
</style>
