<template lang="pug">
.documentation
  .keywords(@click="showNotification")
    .title
      ui-radio-button(v-model="modelValue.wordSearchMode" :options="operatorOptions")
      cleanable-title.docs-title(:disabled="overlapKeywords" @reset="$emit('reset', 'docsSearch.keywords')")
        span ДОКУМЕНТАЦИЯ
        template(#additional)
          ui-warning(placement="bottom-start")
            .warning-content
              span.label Модификатор И-ИЛИ между ключевыми словами поиска и ключевыми словами файлов документации
              ul
                li В положении <b>И</b> запрос выдаст все тендеры, в которых одновременно присутствуют как <b>ключевые слова поиска</b> по карточкам тендеров, так и ключевые слова <b>поиска по документации</b>.
                li В положении <b>ИЛИ</b> запрос выдаст все тендеры, в которых ключевые слова встречаются в карточке тендера <b>или</b> в файлах документации.

    tag-input.search_panel_frame(
      writable
      v-model="modelValue.docsSearch.keywords"
      placeholder="Введите ключевые слова для поиска по документации"
      :disabled="isOverlap"
    )
  .exceptions(@click="showNotification")
    cleanable-title(:disabled="overlapKeywords" @reset="$emit('reset', 'docsSearch.exceptionWords')") СЛОВА ИСКЛЮЧЕНИЯ
    tag-input.search_panel_frame(
      writable
      v-model="modelValue.docsSearch.exceptionWords"
      placeholder="Введите слова исключения из поиска"
      :disabled="isOverlap"
    )
  ui-checkbox(
    id="overlap_keywords"
    :model-value="overlapKeywords"
    :disabled="!!modelValue.lotsSearch.expertSearch"
    label="Поиск по документации совпадает с ключевыми словами поиска"
    message="При поиске в режиме профессиональных запросов, документацию нужно заполнять вручную. Поиск по документации не поддерживает профессиональный режим"
    @update:model-value="useLotsSearchKeywords"
  )
  .warnings
    warning-message.margin(v-if="showStatusWarning && isDocsSet" type="warning")
      span(v-if="modelValue?.lotStatusIds?.includes(3) && modelValue?.lotStatusIds?.includes(4)") У Вас установлен фильтр по тендерам с "отмененными" и "завершенными" этапами.
      span(v-else) У Вас установлен фильтр по тендерам с "{{ modelValue?.lotStatusIds?.includes(3) ? 'отмененным' : 'завершенным' }}" этапом.
      |
      | Поиск по документации работает только для тендеров на этапах "подача заявки" или "работа комиссии".
    warning-message.margin(v-if="warningMessage && (isOverlap || modelValue.docsSearch.exceptionWords.length)" :type="isOverlap ? 'warning' : 'danger'")
      span(v-html="warningMessage")
</template>

<script lang="ts">
import { defineComponent, watch, ref, computed } from "vue";
import { useVModel } from "@vueuse/core";
import { setDocsWords } from "@/utils/searchForm/searchFormWorker";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";

import TagInput from "@/components/tagInput/TagInput.vue";
import RadioGroup from "@/components/ui/radio/RadioGroup.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import WarningMessage from "~/components/ui/alert/WarningMessage.vue";

import type { PropType } from "vue";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";
import UiRadioButton from "~/components/ui/radio/UiRadioButton.vue";
import UiWarning from "~/components/ui/tooltip/UiWarning.vue";

export default defineComponent({
  name: "DocumentationFilter",
  components: {
    UiWarning,
    UiRadioButton,
    WarningMessage,
    UiCheckbox,
    RadioGroup,
    TagInput,
    CleanableTitle,
  },
  props: {
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
    overlapKeywords: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  setup(props, { emit }) {

    const searchFormModel = useVModel(props, 'modelValue', emit);
    const overlapKeywords = ref(false);

    const {
      showWarning,
    } = useNotificationsStore();

    function showNotification() {
      if (overlapKeywords.value && !searchFormModel.value.lotsSearch.expertSearch) {
        showWarning('Снимите галочку "Поиск по документации совпадает с ключевыми словами поиска"')
      }
    }

    function useLotsSearchKeywords(v: boolean) {
      overlapKeywords.value = v
      if (v) setDocsWords(searchFormModel);
    }

    const isExpertSearch = computed(() => !!props.modelValue.lotsSearch.expertSearch)
    const isDocsSet = computed(() =>
      !!searchFormModel.value.docsSearch.keywords.length
      || !!searchFormModel.value.docsSearch.exceptionWords.length
      || overlapKeywords.value
    )

    const isOverlap = computed(() => overlapKeywords.value && !searchFormModel.value.lotsSearch.expertSearch)

    const warningMessage = computed(() => {
      if (isOverlap.value)
        return 'Для доступа к редактированию ключевых слов по документации, <b>снимите</b> галочку "Поиск по документации совпадает с ключевыми словами поиска".'
      if (searchFormModel.value.wordSearchMode === 2 && !searchFormModel.value.docsSearch.keywords.length)
        return 'Обратите внимание, <b>нельзя</b> искать тендеры по словам исключениям без введенных <b>ключевых слов файлов документации</b>.'
      if (searchFormModel.value.wordSearchMode === 1 && !(searchFormModel.value.docsSearch.keywords.length || searchFormModel.value.lotsSearch.keywords.length || searchFormModel.value.lotsSearch.expertSearch))
        return 'Обратите внимание, <b>нельзя</b> искать тендеры по словам исключениям без введенных <b>ключевых слов поиска или файлов документации</b>.'
      return ''
    })

    const showStatusWarning = computed(() => searchFormModel.value?.lotStatusIds?.includes(3) || searchFormModel.value?.lotStatusIds?.includes(4))

    watch(isExpertSearch, () => {
      if (isExpertSearch.value && overlapKeywords.value) overlapKeywords.value = false
    })

    watch(() => [props.modelValue.lotsSearch.keywords, props.modelValue.lotsSearch.exceptionWords], () => {
      if (overlapKeywords.value) setDocsWords(searchFormModel);
    }, { deep: true });

    const operatorOptions = [
      { id: 'docs-and', label: 'И', value: 1 },
      { id: 'docs-or', label: 'ИЛИ', value: 2 },
    ]

    return {
      operatorOptions,
      showNotification,
      useLotsSearchKeywords,
      showStatusWarning,
      warningMessage,
      overlapKeywords,
      isDocsSet,
      isOverlap,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/searchForm";
@import "@/assets/styles/mixin/main";

.documentation {
  display: flex;
  flex-flow: column;
  gap: 16px;
  font-size: 14px;

  .search_panel_frame {
    box-shadow: none !important;
    height: 100% !important;
    min-height: 120px !important;
    max-height: 146px !important;
  }
}

.margin {
  margin: 8px 0;
}

.title {
  display: flex;
  flex-flow: row;
  gap: 16px;
  margin-bottom: 8px;
  align-items: center;
}

.warning-content {
  display: flex;
  flex-flow: column;
  gap: 6px;
  max-width: 420px;
  padding: 4px;

  .label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
}

.docs-title {
  margin-bottom: 0;
  width: 100%;
}

.keywords, .exceptions {
  display: flex;
  flex-flow: column;
}
</style>
